module.exports = {
  'title': '新しいアカウントを作る',
  'title_invitation': 'アカウントの作成',
  'subtitle': '入会して特別特典を利用する',
  'placeholder_firstname': 'ファーストネーム（名）',
  'placeholder_lastname': 'ラストネーム（姓）',
  'placeholder_email': 'Eメールアドレス',
  'placeholder_password': 'パスワードの設定',
  'placeholder_nationality': '国籍',
  'placeholder_tc': '利用規約を読み、同意します。',
  'placeholder_tc_link': '利用規約',
  'placeholder_subscribe': '新しいサービスやプロモーションの情報を受け取ることを希望します。',
  'button_label': '申請する',
  'title_modal_email_exist': 'Eメールアドレスはすでに登録ずみです',
  'description_modal_email_exist': 'ご入力頂いたEメールアドレスはすでに登録済みです。ログインされますか？',
  'btn_redirect_login': 'ログイン',
  'btn_change_email': 'Eメールアドレスの変更',
  'placeholder_selectbox': '選択ください',
  'title_alert_message': 'Invitation',
  'btn_label_signin': '確定'
}