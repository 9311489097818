import React, { Component } from "react";

import Layout from "../layouts/ja";
import SEO from "../components/seo";
import RegisterInvitation from "../services/SignUp";
import SignUpLang from "../data/static-pages/pages/signup/ja";
import invitationLangData from "../data/static-pages/pages/invitation/ja";

export default class InvitationPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.urlRedirect = "/ja/verify-account";
    this.termsUrl = "/ja/terms-and-conditions-8";
    this.langKey = this.props.pageContext.langKey;
    this.urlMemberPages = "/ja/member";
    this.isAuth0Enabled = process.env.GATSBY_ENABLE_AUTH0 === "true";
  }

  render() {
    return (
      <Layout
        location={this.props.location}
        showBookNowButton={!this.isAuth0Enabled}>
        <SEO
          title="Sign Up With Invitation "
          keyword="Sign Up With Invitation"
          decription="Sign Up With Invitation"
        />

        <RegisterInvitation
          location={this.props.location}
          urlRedirect={this.urlRedirect}
          langData={SignUpLang}
          langKey={this.langKey}
          urlMemberPages={this.urlMemberPages}
          termsUrl={this.termsUrl}
          invitationLangData={invitationLangData}
        />
      </Layout>
    );
  }
}
